import {MenuItem} from 'primeng/api';

export const menuItems: MenuItem[] = [
  {
    label: '',
    items: [
      {label: 'DASHBOARD', icon: 'pi pi-home', routerLink: '/dashboard'},
      {label: 'SCREENS', icon: 'pi pi-desktop', routerLink: '/screens'}
    ],
  },
  /**{
    label: 'CONTENT',
    items: [
      {label: 'SCHEDULE CONTENT', icon: 'pi pi-calendar-plus', routerLink: '/content'},
    ],
  }*/
];
