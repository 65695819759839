import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import { ChartModule } from 'primeng/chart';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TagModule } from 'primeng/tag';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  imports: [],
  exports: [
    ButtonModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    ChartModule,
    SkeletonModule,
    TableModule,
    TooltipModule,
    ToastModule,
    DropdownModule,
    MessagesModule,
    OverlayPanelModule,
    TagModule,
    CalendarModule,
    DialogModule,
    MultiSelectModule
  ],
})
export class PrimeNGModule {}
